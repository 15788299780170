import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';
import { useUser } from '../../../store/user';
import TitleBox from '../../../ui-components/title-box';

const SecondaryMenu = ({ isLuisina }) => {
  const accountStore = useAccount();
  const userStore = useUser();

  const [userConfig, setUserConfig] = useState({});
  const [session, setSession] = useState(null);
  const [showAccessCodebox, setShowAccessCodebox] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    // accountStore.getAccountConfig();
    userStore.gerUserLogin();
  }, []);

  useEffect(() => {
    if (null != userStore.session) {
      setSession(userStore.session);
    }
  }, [userStore.session]);

  useEffect(() => {
    if (null != accountStore.config) {
      setUserConfig(accountStore.config);
    }
  }, [accountStore.config]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAccessCodebox(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const [code, setCode] = useState('');

  const handleLoginWithCode = () => {
    userStore.loginWithCode(code);
  };

  const handleLogout = () => {
    userStore.logout();
    window.location.reload();
  };

  return (
    <div className='secondary-menu'>
      {
        isLuisina ?
          <div className='box-items container'
            style={{ height: '80px', display: 'flex', fontSize: '15px', padding: '16px 0px' }}>
            <div className='start-items'>
              <div className='item'>
                <Link to='/' className='link'>
                  <img
                    src={accountStore.config?.logo_web ? Constants.BASE_URL + accountStore.config.logo_web : ''}
                    alt='logo'
                    className='logo'
                    style={{ height: '70px', marginTop: '-10px' }}
                  />
                </Link>
              </div>
            </div>
            <div className='end-items'>
              <div className='item' style={{ marginRight: '2rem' }}>
                <span className={`icon ${window.style_config?.iconsMenuWhite ? 'wp-white' : 'wp'}`}></span>
                <a className='link' href='#'>
                  {userConfig?.contact_phone}
                </a>
              </div>
              <div className='item' style={{ marginRight: '2rem' }}>
                <span className={`icon ${window.style_config?.iconsMenuWhite ? 'mail-white' : 'mail'}`}></span>
                <a className='link' href='#'>
                  {userConfig?.contact_email}
                </a>
              </div>
              <div className='item'>
                {session === undefined || session === null ? (
                  <React.Fragment>
                    <span className={`icon ${window.style_config?.iconsMenuWhite ? 'signin-white' : 'signin'}`}></span>
                    <Link to='/ingresar' className='link'>
                      INGRESO | REGISTRO
                    </Link>
                  </React.Fragment>
                ) : (
                  <Link style={{ textDecoration: 'none' }} to='/mi-cuenta'>
                    Hola, {session?.name}
                  </Link>
                )}
              </div>
              <div className='item'>
                {session === undefined || session === null ? (
                  <button
                    className='access-code'
                    onClick={() => setShowAccessCodebox(true)}
                  >
                    Código de acceso
                  </button>
                ) : (
                  <button className='access-code' onClick={() => handleLogout()}>
                    SALIR
                  </button>
                )}
              </div>
            </div>
          </div>
          :

          <div className='box-items container'>
            <div className='start-items'>
              <div className='item'>
                <span className={`icon ${window.style_config?.iconsMenuWhite ? 'wp-white' : 'wp'}`}></span>
                <a className='link' href='#'>
                  {userConfig?.contact_phone}
                </a>
              </div>
              <div className='item'>
                <span className={`icon ${window.style_config?.iconsMenuWhite ? 'mail-white' : 'mail'}`}></span>
                <a className='link' href='#'>
                  {userConfig?.contact_email}
                </a>
              </div>
            </div>
            <div className='end-items'>
              <div className='item'>
                {session === undefined || session === null ? (
                  <React.Fragment>
                    <span className={`icon ${window.style_config?.iconsMenuWhite ? 'signin-white' : 'signin'}`}></span>
                    <Link to='/ingresar' className='link'>
                      INGRESO | REGISTRO
                    </Link>
                  </React.Fragment>
                ) : (
                  <Link style={{ textDecoration: 'none' }} to='/mi-cuenta'>
                    Hola, {session?.name}
                  </Link>
                )}
              </div>
              <div className='item'>
                {session === undefined || session === null ? (
                  <button
                    className='access-code'
                    onClick={() => setShowAccessCodebox(true)}
                  >
                    Código de acceso
                  </button>
                ) : (
                  <button className='access-code' onClick={() => handleLogout()}>
                    SALIR
                  </button>
                )}
              </div>
            </div>
          </div>
      }

      {showAccessCodebox && (
        <div className='access-code-box'>
          <div className='dialog' ref={wrapperRef}>
            <TitleBox title='Ingresá tu código de acceso' />
            <div className='custom-input'>
              <input
                placeholder='Código de acceso'
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLoginWithCode();
                  }
                }}
              />
              <button
                className='action'
                onClick={() => {
                  handleLoginWithCode();
                }}
              >
                <img
                  src={require('../../../assets/icons/right-arrow-big.svg')}
                />
              </button>
            </div>
            <div className='separator'></div>
            <p>
              Si estás registrado y tenes problemas para ingresar solicitá un
              código de acceso
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SecondaryMenu;
import React, { useEffect, useState, useRef } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../../Constants';
import { useAccount } from '../../../../store/account';
import { useUser } from '../../../../store/user';
import TitleBox from '../../../../ui-components/title-box';
import './styles.scss';

const MobileMenu = () => {
  const accountStore = useAccount();
  const userStore = useUser();
  const wrapperRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [showAccessCodebox, setShowAccessCodebox] = useState(false);

  useEffect(() => {
    userStore.gerUserLogin();
  }, []);

  useEffect(() => {
    if (null != userStore.session) {
      setSession(userStore.session);
    }
  }, [userStore.session]);

  const handleOpenMenu = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    userStore.logout();
    window.location.reload();
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAccessCodebox(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  const [code, setCode] = useState('');

  const handleLoginWithCode = () => {
    userStore.loginWithCode(code);
  };

  return (
    <Fragment>
      <a href={`https://wa.me/${accountStore.config?.whatsapp}`}
        className="whatsapp" target="_blank"> <i className="fab fa-whatsapp"></i>
      </a>
      <div className='space'></div>
      <div className={`mobile-menu ${window.socket_io_customer === 'luisina_veiga' ? 'mobile-menu-lv' : ''}`}>
        <div className='header'>
          <div className='item'>
            <Link to='/' className='link'>
              <img
                src={accountStore.config?.logo_web ? Constants.BASE_URL + accountStore.config.logo_web : ''}
                alt='logo'
                className='logo'
              />
            </Link>
          </div>
          <div className='item'>
            <button onClick={handleOpenMenu}>
              <i
                class='fas fa-bars'
                style={{ color: '#fff', fontSize: '26px' }}
              ></i>
            </button>
          </div>
        </div>
        {open && (
          <div className='full-menu'>
            <div className='pages'>
              <Link to='/' className='link' onClick={handleOpenMenu}>
                <div className='item'>Inicio</div>
              </Link>
              {
                accountStore?.config?.include_page_conditions ? (
                  <Link to='/terminos-condiciones' className='link' onClick={handleOpenMenu}>
                    <div className='item'>Término & condiciones</div>
                  </Link>
                ) : null
              }
              {
                accountStore?.config?.include_page_buy && (
                  <Link to='/comprar' className='link' onClick={handleOpenMenu}>
                    <div className='item'>Comprar</div>
                  </Link>
                )
              }
              {
                accountStore?.config?.include_page_sell && (
                  <Link to='/vender' className='link' onClick={handleOpenMenu}>
                    <div className='item'>Vender</div>
                  </Link>
                )
              }
              <Link to='/ayuda' className='link' onClick={handleOpenMenu}>
                <div className='item'>F.A.Q</div>
              </Link>
              <Link to='/contacto' className='link' onClick={handleOpenMenu}>
                <div className='item'>Contacto</div>
              </Link>
            </div>
            <div className='user-pages'>
              {session === undefined || session === null ? (
                <Link to='/ingresar' className='link' onClick={handleOpenMenu}>
                  INGRESO | REGISTRO
                </Link>
              ) : (
                <Link style={{ textDecoration: 'none' }} to="/mi-cuenta" onClick={handleOpenMenu}>
                  Hola, {session?.name}
                </Link>
              )}
              <div className="login-with-code">
                {session === undefined || session === null ? (
                  <button
                    className='access-code'
                    onClick={() => {
                      handleOpenMenu()
                      setShowAccessCodebox(true)
                    }}
                  >
                    Código de acceso
                  </button>
                ) : (
                  <button className='access-code' onClick={() => handleLogout()}>
                    SALIR
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {showAccessCodebox && (
          <div className='access-code-box'>
            <div className='dialog' ref={wrapperRef}>
              <TitleBox title='Ingresá tu código de acceso' />
              <div className='custom-input'>
                <input
                  placeholder='Código de acceso'
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleLoginWithCode();
                    }
                  }}
                />
                <button
                  className='action'
                  onClick={() => {
                    handleLoginWithCode();
                  }}
                >
                  <img
                    src={require('../../../../assets/icons/right-arrow-big.svg')}
                  />
                </button>
              </div>
              <div className='separator'></div>
              <p>
                Si estás registrado y tenes problemas para ingresar solicitá un
                código de acceso
              </p>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MobileMenu;
/*
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { Fragment } from 'react';
import { Constants } from '../../../../Constants';
import './styles.scss';

class MobileMenu extends Component {
  state = {
    open: false
  }

  handleOpenMenu = async () => {
    await this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <Fragment>
        <a href={`https://wa.me/${this.props.configAccount.config?.whatsapp}`}
          className="whatsapp" target="_blank"> <i className="fab fa-whatsapp"></i>
        </a>
        <div className='space'></div>
        <div className='mobile-menu'>
          <div className='header'>
            <div className='item'>
              <Link to='/' className='link'>
                <img
                  src={this.props.configAccount.config?.logo_web ? Constants.BASE_URL + this.props.configAccount.config.logo_web : ''}
                  alt='logo'
                  className='logo'
                />
              </Link>
            </div>
            <div className='item'>
              <button onClick={() => this.handleOpenMenu()}>
                <i
                  class='fas fa-bars'
                  style={{ color: '#fff', fontSize: '26px' }}
                ></i>
              </button>
            </div>
          </div>
          {this.state.open && (
            <div className='full-menu'>
              <div className='pages'>
                <Link to='/' className='link' onClick={() => this.handleOpenMenu()}>
                  <div className='item'>Inicio</div>
                </Link>
                <Link to='/terminos-condiciones' className='link' onClick={() => this.handleOpenMenu()}>
                  <div className='item'>Término & condiciones</div>
                </Link>
                <Link to='/ayuda' className='link' onClick={() => this.handleOpenMenu()}>
                  <div className='item'>F.A.Q</div>
                </Link>
                <Link to='/contacto' className='link' onClick={() => this.handleOpenMenu()}>
                  <div className='item'>Contacto</div>
                </Link>
              </div>
              <div className='user-pages'>
                {
                  this.props.sessionProps.account === null ?
                    <Link to='/ingresar' className='link' onClick={() => this.handleOpenMenu()}>
                      LOGIN | REGISTRO
                    </Link>
                    :
                    <Link style={{ textDecoration: 'none' }} to="/mi-cuenta" onClick={() => this.handleOpenMenu()}>
                      Hola, {this.props.sessionProps.account.name}
                    </Link>
                }
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  configAccount: state.accountReducer
})

export default connect(mapStateToProps)(MobileMenu);
*/